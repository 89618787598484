import React from 'react';
import SwitchSection from '../../SwitchSection';
import NamedSwitch from '../../SwitchSection/components/NamedSwitch';
import { useTranslation } from 'react-i18next';

export default function CalendarSection({ stateHandler, isEditing }) {
    const { t } = useTranslation();
    
    return (
    <SwitchSection sectionTitle={t('global.calendar')}>
      <NamedSwitch name={t('enabled')} stateHandler={stateHandler[0]} isEditing={isEditing} />
      <NamedSwitch name={t('editConfig')} stateHandler={stateHandler[1]} isEditing={isEditing} />
    </SwitchSection>
  );
}
