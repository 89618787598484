const dateFormatList = [
  {
    value: "dd-MM-yyyy",
    description: "DD-MM-YYYY ",
  },
  {
    value: "dd/MM/yyyy",
    description: "DD/MM/YYYY ",
  },
  {
    value: "dd.MM.yyyy",
    description: "DD.MM.YYYY",
  },
  {
    value: "dd MM yyyy ",
    description: "DD MM YYYY",
  },
  {
    value: "dd-MM-yy",
    description: "DD-MM-YY",
  },
  {
    value: "dd.MM.yy",
    description: "DD.MM.YY",
  },
  {
    value: "dd/MM/yy",
    description: "DD/MM/YY",
  },
  {
    value: "d.MM.yy",
    description: "D.MM.YY",
  },
  {
    value: "d-M-yy",
    description: "D-M-YY",
  },
  {
    value: "d/M/yy",
    description: "D/M/YY",
  },
  {
    value: "d.M.yy",
    description: "D.M.YY",
  },
  {
    value: "d.M.yy.",
    description: "D.M.YY.",
  },
  {
    value: "d-M-yyyy",
    description: "D-M-YYYY",
  },
  {
    value: "d/M/yyyy",
    description: "D/M/YYYY",
  },
  {
    value: "d.M.yyyy",
    description: "D.M.YYYY",
  },
  {
    value: "d. M. yyyy.",
    description: "D. M. YYYY.",
  },
  {
    value: "d. M. yyyy",
    description: "D. M. YYYY",
  },
  {
    value: "d MMM, yyyy",
    description: "D MMM, YYYY",
  },
  {
    value: "dd MMM, yyyy",
    description: "DD MMM, YYYY",
  },
  {
    value: "d. MMMM yyyy",
    description: "D. MMMM YYYY",
  },
  {
    value: "DD.MM.yyyy.",
    description: "DD.MM.YYYY.",
  },
  {
    value: "dd.MM.yy.",
    description: "DD.MM.YY.",
  },
  {
    value: "yyyy-MM-dd",
    description: "YYYY-MM-DD",
  },
  {
    value: "yyyy/MM/dd",
    description: "YYYY/MM/DD",
  },
  {
    value: "yyyy.MM.dd",
    description: "YYYY.MM.DD",
  },
  {
    value: "yyyy MM dd",
    description: "YYYY MM DD",
  },
  {
    value: "yy/MM/dd",
    description: "YY/MM/DD",
  },
  {
    value: "yy-MM-dd",
    description: "YY-MM-DD",
  },
  {
    value: "yy.M.d",
    description: "YY.M.D",
  },
  {
    value: "yy-M-d",
    description: "YY-M-D",
  },
  {
    value: "yy. M. d",
    description: "YY. M. D",
  },
  {
    value: "yyyy/M/d",
    description: "YYYY/M/D",
  },
  // {
  //   value: "YYYY年MM月DD日",
  //   description: "YYYY年MM月DD日",
  // },
  // {
  //   value: "YY年M月D日",
  //   description: "YY年M月D日",
  // },
  {
    value: "yyyy.MM.dd.",
    description: "YYYY.MM.DD.",
  },
  {
    value: "yyyy. MM. dd",
    description: "YYYY. MM. DD",
  },
  {
    value: "MM-dd-yyyy",
    description: "MM-DD-YYYY",
  },
  {
    value: "MM/dd/yyyy",
    description: "MM/DD/YYYY",
  },
  {
    value: "MM.dd.yyyy",
    description: "MM.DD.YYYY",
  },
  {
    value: "MM dd yyyy",
    description: "MM DD YYYY",
  },
  {
    value: "MM-dd-yy",
    description: "MM-DD-YY",
  },
  {
    value: "MM/dd/yy",
    description: "MM/DD/YY",
  },
  {
    value: "M/dd/yy",
    description: "M/DD/YY",
  },
  {
    value: "MMM-dd-yyyy",
    description: "MMM-DD-YYYY",
  },
  {
    value: "MMM dd, yyyy",
    description: "MMM DD, YYYY",
  },
  {
    value: "MMM d, yyyy",
    description: "MMM D, YYYY",
  },
  {
    value: "MMMM d, yyyy",
    description: "MMMM D, YYYY",
  },
  {
    value: "yyyy.dd.MM",
    description: "YYYY.DD.MM",
  },
  {
    value: "yy.d.M",
    description: "YY.D.M",
  },
];

export default dateFormatList;
