import React from 'react';
import SwitchSection from '../../SwitchSection';
import NamedSwitch from '../../SwitchSection/components/NamedSwitch';
import { useTranslation } from 'react-i18next';

export default function ContactsSection({ stateHandler, isEditing }) {
    const { t } = useTranslation();
    
    return (
    <SwitchSection sectionTitle={t('contacts.contacts')}>
      <NamedSwitch name={t('enabled')} stateHandler={stateHandler[0]} isEditing={isEditing} />
      <NamedSwitch name={t('editConfig')} stateHandler={stateHandler[1]} isEditing={isEditing} />
      <NamedSwitch name={t('contacts.export')} stateHandler={stateHandler[2]} isEditing={isEditing} />
      <NamedSwitch name={t('contacts.import')} stateHandler={stateHandler[3]} isEditing={isEditing} />
      <NamedSwitch name={t('contacts.join')} stateHandler={stateHandler[4]} isEditing={isEditing} />
      <NamedSwitch name={t('contacts.delete')} stateHandler={stateHandler[5]} isEditing={isEditing} />
  </SwitchSection>
  );
}
