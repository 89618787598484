import React from 'react';
import SwitchSection from '../../SwitchSection';
import NamedSwitch from '../../SwitchSection/components/NamedSwitch';
import { useTranslation } from 'react-i18next';

export default function TicketsSection({ stateHandler, isEditing }) {
    const { t } = useTranslation();
    
    return (
    <SwitchSection sectionTitle={'Tickets'}>
      <NamedSwitch name={t('enabled')} stateHandler={stateHandler[0]} isEditing={isEditing} />
      <NamedSwitch name={t('tickets.create')} stateHandler={stateHandler[1]} isEditing={isEditing} />
      <NamedSwitch name={t('tickets.delete')} stateHandler={stateHandler[2]} isEditing={isEditing} />
      <NamedSwitch name={t('tickets.edit')} stateHandler={stateHandler[3]} isEditing={isEditing} />
      <NamedSwitch name={t('tickets.update')} stateHandler={stateHandler[4]} isEditing={isEditing} />
  </SwitchSection>
  );
}
