import React from 'react';
import SwitchSection from '../../SwitchSection';
import NamedSwitch from '../../SwitchSection/components/NamedSwitch';
import { useTranslation } from 'react-i18next';

export default function ServicesSection({ stateHandler, isEditing }) {
    const { t } = useTranslation();
    
    return (
    <SwitchSection sectionTitle={'Service'}>
      <NamedSwitch name={t('enabled')} stateHandler={stateHandler[0]} isEditing={isEditing} />
    </SwitchSection>
  );
}
