import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfilesContext } from 'features/Views/Users/components/TableBody/components/ProfilePanel/context/profilesContext';
// Components/ui
import { TabPanel } from 'components';
import { SwitchSection, UserItem } from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectProfileUsers } from 'redux/features/profilesSlice/profilesSlice';
import { Box } from '@mui/material';
import NamedSwitch from './components/SwitchSection/components/NamedSwitch';
import { CalendarSection, CallsSection, ChatSection, CollectionsSection, ContactsSection, ConversationsSection, MarketingSection, ServicesSection, TicketsSection } from './components/Sections';

export default function ProfileInfoBodyPanels({ tab }) {
  const { t } = useTranslation();
  const {
    collectionsSwitches,
    conversationSwitches,
    contactSwitches,
    marketingSwitches,
    serviceSwitches,
    ticketSwitches,
    chatSwitches,
    callSwitches,
    calendarSwitches,
    // agentSwitches,
  } = useContext(ProfilesContext);
  const { isEditing } = useContext(ProfilesContext);
  const profileUsers = useSelector(selectProfileUsers);

  return (
    <>
      <TabPanel tabValue={tab} index={0}>
        {/* Conversations */}
        <ConversationsSection
          stateHandler={conversationSwitches}
          isEditing={isEditing}
        />
        {/* Tickets */}
        <TicketsSection
          stateHandler={ticketSwitches}
          isEditing={isEditing}
        />
        {/* Collections */}
        <CollectionsSection
          stateHandler={collectionsSwitches}
          isEditing={isEditing}
        />
        {/* Service */}
        <ServicesSection
          stateHandler={serviceSwitches}
          isEditing={isEditing}
        />
        {/* Marketing */}
        <MarketingSection
          stateHandler={marketingSwitches}
          isEditing={isEditing}
        />
        {/* Contacts */}
        <ContactsSection
          stateHandler={contactSwitches}
          isEditing={isEditing}
        />
        {/* Chat */}
        <ChatSection
          stateHandler={chatSwitches}
          isEditing={isEditing}
        />
        {/* Calls */}
        <CallsSection
          stateHandler={callSwitches}
          isEditing={isEditing}
        />
        {/* Calendar  */}
        <CalendarSection
          stateHandler={calendarSwitches}
          isEditing={isEditing}
        />
      </TabPanel>
      <TabPanel tabValue={tab} index={1}>
        <Box p={1} display={'flex'} flexDirection={'column'} gap={1}>
          {profileUsers.map((user, index) => (
            <UserItem
              avatarUrl={user.imageUrl}
              displayName={user.name}
              key={index}
            />
          ))}
        </Box>
      </TabPanel>
    </>
  );
}
