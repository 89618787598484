import React, { useContext } from "react";
import { StyleContext } from "style/styleProvider";
import { ProfilesContext } from "features/Views/Users/components/TableBody/components/ProfilePanel/context/profilesContext";
//components/ui
import Typography from "@mui/material/Typography";
import Divider from "@material-ui/core/Divider";
import NamedSwitch from "./components/NamedSwitch";
import { Box } from "@mui/material";

const SwitchSection = ({
  sectionTitle,
  children
  // sectionActions,
  // sectionStateHandlers,
}) => {
  const { fontSize } = useContext(StyleContext);
  const { isEditing } = useContext(ProfilesContext);

  return (
    <Box style={{ paddingTop: 5 }}>
      <Typography
        sx={{ color: "text.primary" }}
        fontWeight={"500"}
        fontSize={fontSize.lg}
      >
        {sectionTitle}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        py={3}
        pl={3}
        alignItems={"flex-start"}
        style={{ gap: 12 }}
      >
        {/* {sectionActions.map((value: string, index: number) => (
          <NamedSwitch
            key={index}
            name={value}
            stateHandler={sectionStateHandlers[index]}
            isEditing={isEditing}
          />
        ))} */}
        {children}
      </Box>
      <Divider />
    </Box>
  );
};

export default SwitchSection;
