import React from 'react';
import SwitchSection from '../../SwitchSection';
import NamedSwitch from '../../SwitchSection/components/NamedSwitch';
import { useTranslation } from 'react-i18next';

export default function ConversationsSection({ stateHandler, isEditing }) {
    const { t } = useTranslation();
    
    return (
    <SwitchSection sectionTitle={t('conversations.conversations')}>
      <NamedSwitch name={t('enabled')} stateHandler={stateHandler[0]} isEditing={isEditing} />
      <NamedSwitch name={t('conversations.cannedResponsesAdmin')} stateHandler={stateHandler[1]} isEditing={isEditing} />
      <NamedSwitch name={t('conversations.cannedResponsesCreate')} stateHandler={stateHandler[2]} isEditing={isEditing} />
      <NamedSwitch name={t('conversations.dashboard')} stateHandler={stateHandler[3]} isEditing={isEditing} />
      <NamedSwitch name={t('editConfig')} stateHandler={stateHandler[4]} isEditing={isEditing} />
      <NamedSwitch name={t('conversations.editMsgNote')} stateHandler={stateHandler[5]} isEditing={isEditing} />
      <NamedSwitch name={t('conversations.endFromInbox')} stateHandler={stateHandler[6]} isEditing={isEditing} />
      <NamedSwitch name={t('conversations.enterIncognitoMode')} stateHandler={stateHandler[7]} isEditing={isEditing} />
      <NamedSwitch name={t('conversations.showUnassignedConversations')} stateHandler={stateHandler[8]} isEditing={isEditing} />
    </SwitchSection>
  );
}
